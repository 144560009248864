import {reactive, toRefs,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import T003CardUtil ,{IT003CardDataObj} from './t003CardUtil';
import html2pdf from 'html2pdf.js';
export default defineComponent({
    name:'PiTemplateCard',
    title:'PI模板',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IT003CardDataObj=reactive<IT003CardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/piTemplate')
            },
            //表单
            form:{
                customerBean:{},
                customerDeliveryBean:{},
                orderData:{
                    orderContentData:[],
                    sumInfo:{}
                },
                piBean:{}
            },
            //表单验证规则
            rules: {},
            otherParams:{
                fromTemplate:false,//是否从template那里打开的预览模板（tmd，否则分批转运那里始终有内容显示）
                customerBean:{},
                customerDeliveryBean:{},
                piBean:{},
                accessoryData:[],//配件信息
                amount:{//中间表格的总金额
                    totalAmount:0,
                    totalEnAmount:''//英文大写
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new T003CardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            proxy.dialogInst.options.title=engineInst.engineParams.title;
            if(res.dataInfo){
                dataObj.otherParams.customerBean=res.dataInfo.customerBean;
                dataObj.otherParams.customerDeliveryBean=res.dataInfo.customerDeliveryBean;
                dataObj.otherParams.piBean=res.dataInfo.piBean;
                let totalAmount:number=0;
                Object.keys(res.dataInfo.accessoryData).forEach((key:string)=>{
                    let item=JSON.parse(key);
                    // item.price=utils.UtilPub.toAmountFormat(item.price,false,2);
                    // item.amount=utils.UtilPub.toAmountFormat(item.amount,false,2);
                    item.child=res.dataInfo.accessoryData[key];
                    // item.child.forEach((item2:any)=>{
                    //     item2.price=utils.UtilPub.toAmountFormat(item2.price,false,2);
                    //     item2.amount=utils.UtilPub.toAmountFormat(item2.amount,false,2);
                    // })
                    if(item.child && item.child.length>0){
                        item.modelName=item.child[0]['model'];
                    }
                    dataObj.otherParams.accessoryData.push(item);
                    totalAmount+=item.amount;
                });
                dataObj.otherParams.amount.totalAmount=totalAmount;
                dataObj.otherParams.amount.totalEnAmount=utils.UtilPub.toEnBigAmount(totalAmount);
            }
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const setParam=(inst:any)=>{
            return{code:inst.engineParams.code,templateId:inst.engineParams.templateId,from:'t003',piId:inst.engineParams.piId};
        }
        const printPdf=()=>{
            let dialogContent:any = (document.getElementById('printContent') as any).innerHTML;
            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write("<html><head><title>"+proxy.engine.engineParams.code+"</title></head><body>");
            printWindow.document.write(dialogContent);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
        const downloadPdf=()=>{
            const element = dataObj.refMap.get('printContent'); // 通过 ref 获取要转换为 PDF 的元素
            (html2pdf as any)().from(element).save(proxy.engine.engineParams.code+'.pdf'); // 将元素转换为 PDF 并保存为文件
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler,setParam,printPdf,downloadPdf
        }
    }
});